import { XMarkIcon } from '@heroicons/react/24/solid';
import { route } from 'preact-router';
import styled from 'styled-components';

import Component from '../BaseComponent';
import Helpers from '../Helpers';
import localState from '../LocalState';
import Key from '../nostr/Key';
import { translate as t } from '../translations/Translation';

import { Button, PrimaryButton } from './buttons/Button';
import Copy from './buttons/Copy';
import Follow from './buttons/Follow';
import QRModal from './modal/QRModal';
import Identicon from './Identicon';
import Name from './Name';

const SUGGESTED_FOLLOWS = [
  [
    'npub17y22rum6xrx5pk8e2ew3fwnhxuexls4qxqzrek86lh5rpq7nf7yspdmzl5',
    '"CEO of Bluesky, steward of AT Protocol."',
  ],
  [
    'npub1qv54v0m2q7n7r7dmc2whxm8axh8v6exkvtlpgs2ncaxam2r2qj3spe5duj',
    '"Journalist writing about technology and life online 👩🏻‍💻"',
  ],
  [
    'npub1eyvfn7vkkxwasvajslfywzyancdsc380nuv0pzgh6ecqw8z2cnnsrs685v',
    '"Congresswoman for NY14 repping the Bronx and Queens. Grassroots progressive. Funded by everyday people not lobbyists. A better world is possible."',
  ],
  [
    'npub1e5zl9yg7q8q52fnqa84jexxnug4zthuryswtrg40uz5e7xq4aazsfn88fr',
    '"Video maker"',
  ],
  [
    'npub1ygql5arwdusnhnw9twp2ukz5tyau4jkq5qxum3m9ulfpy8f3pzuq2kcudt',
    '',
  ],
  [
    'npub1u52xkudynn60uvchmyczgd372tv7d0r6pypnpkxja830qrsf2wqsg6tv99',
    '"Entrepreneur Costplusdrugs.com"',
  ],
  [
    'npub1sfxpakmt3ul2kwqp36gf6cmt3yyh93g4qx70j6aag6uajgmxhf4smw3mwc',
    '"Dad, husband, CNN anchor, author, doorman to our two dogs."',
  ],
  [
    'npub1e5zl9yg7q8q52fnqa84jexxnug4zthuryswtrg40uz5e7xq4aazsfn88fr',
    '"Video maker"',
  ],
  [
    'npub1e5zl9yg7q8q52fnqa84jexxnug4zthuryswtrg40uz5e7xq4aazsfn88fr',
    '"Video maker"',
  ],
  ['npub1sg6plzptd64u62a878hep2kev88swjh3tw00gjsfl8f237lmu63q0uf63m', 
    'Former CEO of Twitter'], // jack
];

const NoFollowersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
`;

const CloseIconWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
`;

export default class OnboardingNotification extends Component {
  componentDidMount() {
    localState.get('noFollowers').on(this.inject());
    localState.get('hasNostrFollowers').on(this.inject());
    localState.get('showFollowSuggestions').on(this.inject());
    localState.get('showNoIrisToAddress').on(this.inject());
    localState.get('existingIrisToAddress').on(this.inject());
  }

  renderFollowSuggestions() {
    return (
      <div style="display:flex;flex-direction:column;flex:1">
        <p>{t('follow_someone_info')}</p>
        {SUGGESTED_FOLLOWS.map(([pub, description]) => (
          <div class="profile-link-container">
            <a href={`/${pub}`} className="profile-link">
              <div>
                <Identicon str={pub} width={40} />
              </div>
              <div style="flex: 1">
                <Name pub={pub} placeholder="Suggested follow" />
                <br />
                <small>{description}</small>
              </div>
            </a>
            <Follow id={pub} />
          </div>
        ))}
        <p>
          <PrimaryButton onClick={() => localState.get('showFollowSuggestions').put(false)}>
            {t('done')}
          </PrimaryButton>
        </p>
        <p>
          {t('alternatively')}
          <i> </i>
          <a href={`/${Key.toNostrBech32Address(Key.getPubKey(), 'npub')}`}>
            {t('give_your_profile_link_to_someone')}
          </a>
          .
        </p>
      </div>
    );
  }

  renderNoFollowers() {
    return (
      <NoFollowersWrapper>
        <CloseIconWrapper onClick={() => localState.get('noFollowers').put(false)}>
          <XMarkIcon width={24} />
        </CloseIconWrapper>
        <p>{t('no_followers_yet')}</p>
        <p>
          <Copy text={t('copy_link')} copyStr={Helpers.getMyProfileLink()} />
          <Button onClick={() => this.setState({ showQrModal: true })}>{t('show_qr_code')}</Button>
        </p>
        <small>{t('no_followers_yet_info')}</small>
        {this.state.showQrModal && (
          <QRModal onClose={() => this.setState({ showQrModal: false })} pub={Key.getPubKey()} />
        )}
      </NoFollowersWrapper>
    );
  }

  renderGetIrisAddress() {
    if (!this.state.existingIrisToAddress) {
      return (
        <div>
          <p>Get your own iris.to/username?</p>
          <p>
            <PrimaryButton onClick={() => route('/settings/iris_account')}>
              Yes please
            </PrimaryButton>
            <PrimaryButton onClick={() => localState.get('showNoIrisToAddress').put(false)}>
              No thanks
            </PrimaryButton>
          </p>
        </div>
      );
    }
  }

  render() {
    let content;
    if (this.state.showFollowSuggestions) {
      content = this.renderFollowSuggestions();
    } else if (this.state.showNoIrisToAddress) {
      content = this.renderGetIrisAddress();
    } else if (this.state.noFollowers) {
      content = this.renderNoFollowers();
    }

    if (content) {
      return (
        <div class="msg">
          <div class="msg-content">{content}</div>
        </div>
      );
    }
    return '';
  }
}
