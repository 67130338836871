import FeedComponent from '../components/feed/Feed';
import OnboardingNotification from '../components/OnboardingNotification';
import { translate as t } from '../translations/Translation';

import View from './View';

class Feed extends View {
  constructor() {
    super();
    this.eventListeners = {};
    this.state = { sortedMessages: [] };
    this.id = 'message-view';
    this.class = 'public-messages-view';
  }

  async componentDidMount() {
    this.restoreScrollPosition();

    const res = await fetch('https://bsky.social/xrpc/app.bsky.unspecced.getPopular?limit=30', { 
      method: 'get', 
      headers: new Headers({
          'Authorization': `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzY29wZSI6ImNvbS5hdHByb3RvLmFjY2VzcyIsInN1YiI6ImRpZDpwbGM6bDdncWpuNmJjbDdtbHFkZGhzNm92aHc0IiwiaWF0IjoxNjg0NjE3NjYyLCJleHAiOjE2ODQ2MjQ4NjJ9.cFaZ3li6aCmqZNjP4S9CrmoHFQQ1GISd16yV2mqPbGc`,
          'Content-Type': 'application/json'
      })
    });

    console.log('popular bluesky posts: ', res);
  }

  renderView() {
    const path = this.props.index || 'msgs';
    return (
      <div class="centered-container">
        <div style="display:flex;flex-direction:row">
          <div style="flex:3;width: 100%">
            {this.props.keyword ? (
              <h2>
                {t('search')}: "{this.props.keyword}"
              </h2>
            ) : (
              <OnboardingNotification />
            )}
            <FeedComponent
              scrollElement={this.scrollElement.current}
              keyword={this.props.keyword}
              key={this.props.index || 'feed'}
              index={this.props.index}
              path={path}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default Feed;
